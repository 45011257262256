import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_InfoItem = _resolveComponent("InfoItem")!
  const _component_ListOfSuras = _resolveComponent("ListOfSuras")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('commentary.overview')
    }, null, 8, ["title"]),
    (_ctx.isLoaded(_ctx.info))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.info.payload, (item, index) => {
          return (_openBlock(), _createBlock(_component_InfoItem, {
            key: index,
            item: item
          }, null, 8, ["item"]))
        }), 128))
      : _createCommentVNode("", true),
    _createVNode(_component_ArticleContainer, { class: "my-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListOfSuras)
      ]),
      _: 1
    })
  ], 64))
}